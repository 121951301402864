// react-router-dom components
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from '@mui/material/Grid'
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import MDAlert from "components/MDAlert";
import default_data from "values/default";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import AuthService from '../../../../services/auth.service';

function Cover() {

	const location = useLocation();

    // Function to parse query parameters
    const parseQueryParams = (query) => {
			return query.substring(1).split("&").reduce((acc, curr) => {
				const [key, value] = curr.split("=");
				acc[key] = decodeURIComponent(value);
				return acc;
			}, {});
    };
	
	const [signupDetails, setDetails] = useState({
		firstname: '',
		lastname: '',
		mobile: '',
		password: '',
		email: '',
		confirmpassword: '',
		user_id: '',
		account_id: '',
		sid: '',
		company_name: '',
		planID: '',
	});

	useEffect(() => {
		const queryParams = parseQueryParams(location.search);
		setDetails({
			firstname: queryParams.first_name || '',
			lastname: queryParams.last_name || '',
			mobile: queryParams.phone || '',
			email: queryParams.email?.toLowerCase() || '',
			password: '',
			confirmpassword: '',
			user_id: queryParams.user_id || '',
			account_id: queryParams.account_id || '',
			sid: queryParams.sid || '',
			company_name: queryParams.company_name || '',
			planID: queryParams.planID || '',
		});
	}, [location]);


	const [error, setError] = useState(false);
	const [mobileerror, setmobileError] = useState(false);
	const [passworderror, setPasswordError] = useState(false);
	const [emailerror, setEmailError] = useState(false);
	const [terms, setTerms] = useState(false);
	const [form, setForm] = useState(true);
	const [message, setMessage] = useState("");
	
	const [type, setType] = useState("");

	const onChangeType = (type) => {
		if (type === "Real Estate Agent") {
			setType("Agent");
		} else if (type === "Real Estate Broker") {
			setType("Broker");
		}else if (type === "Influencer"){
			setType("Influencer");
		}else if(type === "Real Estate Agent and Influencer"){
			setType("Real Estate Agent and Influencer");
		}
	}

	const handleTerms = (event) => {
		if(event.target.checked === false){
			setForm(true);
		}
		setTerms(event.target.checked);
		checkform();
	  };

	const handleChange = (e) =>{
		const {name, value} = e.target;
		setDetails((prev) => {
			return {
				...prev,
				[name]: name === 'email' ? value.toLowerCase() : value
			};
		});
		if(name === 'mobile'){
			const inputPhoneNumber = value;
			let formattedPhoneNumber = inputPhoneNumber.replace(/\D/g, ''); // remove all non-numeric characters
			if (formattedPhoneNumber.length >= 3 && formattedPhoneNumber.length < 6) {
			  formattedPhoneNumber = `(${formattedPhoneNumber.slice(0, 3)}) ${formattedPhoneNumber.slice(3)}`;
			} else if (formattedPhoneNumber.length >= 6) {
			  formattedPhoneNumber = `(${formattedPhoneNumber.slice(0, 3)}) ${formattedPhoneNumber.slice(3, 6)}-${formattedPhoneNumber.slice(6, 10)}`;
			}
			setDetails((prev) => {
				return {...prev,[name]:formattedPhoneNumber}
			})
			const mobileNumberRegex = /^[1-9]\d{9}$/;
			const usmobile = /^(\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			(mobileNumberRegex.test(value) || usmobile.test(value)) ? setmobileError(false): setmobileError(true);
		} else if(name === 'email'){
			const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w)*$/;
			emailRegex.test(value) || value.length ===0 ? setEmailError(false): setEmailError(true);
		} else if(name === 'confirmpassword'){
			(signupDetails.password === value)?setPasswordError(false):setPasswordError(true);
		}
		checkform();
	}

	const checkform =()=>{
		if(!emailerror && !passworderror && !mobileerror && signupDetails.email.length !==0 && signupDetails.mobile.length !==0 && signupDetails.password.length !==0 && signupDetails.confirmpassword.length !==0){
			setForm(false);
		}
	}

	const renamePlan = (planID)=> {

		if(planID === "1"){
			return default_data.subscription_plans.free_profile;
		}
		if(planID === "2"){
			return default_data.subscription_plans.basic;
		}
		if(planID === "3"){
			return default_data.subscription_plans.pro_agent;
		}
		return planID;
	}

	const handleRegister = (e) => {
    e.preventDefault();

    setError(false);
    signupDetails.vendor_type = type;
    signupDetails.mra = terms;
    signupDetails.cell_phone = signupDetails.mobile;
    signupDetails.phone = signupDetails.mobile;
		signupDetails.email = signupDetails.email.toLowerCase();

    setMessage("");
    AuthService.register(signupDetails).then(
			(response) => {
				const user = response.data.response.user;
				const { user_id, account_id, sid, company_name, planID } = signupDetails;
				const baseURL = "https://boomtownroi.formstack.com/forms/";
				let formURL = "";

				if (type === "Influencer") {
						formURL = `${baseURL}partners_onboarding?field143721390-first=${user.first_name}&field143721390-last=${user.last_name}&Email=${user.username}&GUID=${user.guid}`;
				} else if (type === "Real Estate Agent and Influencer") {
						formURL = `${baseURL}partner_agent_onboarding?field148281648-first=${user.first_name}&field148281648-last=${user.last_name}&field148281133-first=${user.first_name}&field148281133-last=${user.last_name}&Email=${user.username}&GUID=${user.guid}`;
				} else {
						let params = '';
						
						if (planID) {
							params = `First=${user.first_name}&Last=${user.last_name}&Email=${user.username}&GUID=${user.guid}`;
							params += `&Choose_your_plan=${renamePlan(planID)}`;
							formURL = `${baseURL}myagentfinder_account_plan?${params}`;
						} else {
							params = `field134691190-first=${user.first_name}&field134691190-last=${user.last_name}&Email=${user.username}&GUID=${user.guid}`;

							if (user_id) params += `&field134314786=${user_id}`;
							if (account_id) params += `&field165583978=${account_id}`;
							if (sid) params += `&field165583998=${sid}`;
							if (company_name) params += `&field134417466=${company_name}`;

							formURL = `${baseURL}myagentfinder_account?${params}`;
						}

						
				}
				window.location.href = formURL;
			},
			(error) => {
				setError(true);
				const resMessage = error.response.data.response.error;
				setMessage(resMessage);
			}
	);
};


	return (
		<CoverLayout image="/maf-logo.svg">
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="success"
					mx={2}
					mt={-3}
					p={3}
					mb={1}
					textAlign="center"
					sx={{
						background: `linear-gradient(180deg, #838DF1 0%, #6471ED 100%);`
					}}
				>

					<MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
						Register
					</MDTypography>
					<MDTypography display="block" variant="body2" color="white" my={1}>
						Set up Your MyAgentFinder Agent Account
					</MDTypography>
				</MDBox>
				{error && (<MDAlert color="error" dismissible>{message}</MDAlert>)}
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} sm={12}>
									<Autocomplete
										onChange={(event, value) => onChangeType(value)}
										name = "type"
										inputprops={{ 'aria-label': 'controlled' }}
										options={selectData.Agent_type}
										renderInput={(params) => (
											<FormField {...params} label="I'm" InputLabelProps={{ shrink: true }} />
										)}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={3} pt={3}>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="text" name="firstname" label="First Name" variant="standard" fullWidth value={signupDetails.firstname} onChange={handleChange} />
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="text" name="lastname" label="Last Name" value={signupDetails.lastname} variant="standard" fullWidth  onChange={handleChange}/>
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput error={mobileerror} name="mobile" helperText="your mobile number without country code" placeholder="mobile" type="text" label="Cell Phone" variant="standard" value={signupDetails.mobile} fullWidth onChange={handleChange} />
									{mobileerror && <FormHelperText error>Please enter a valid phone number</FormHelperText>}
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="email" error={emailerror} name="email" label="Email Address" variant="standard" value={signupDetails.email} fullWidth onChange={handleChange}/>
									{emailerror && <FormHelperText error>Please enter a valid email address</FormHelperText>}
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="password" name="password" label="Password" variant="standard" fullWidth  onChange={handleChange}/>
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="password" error={passworderror} name="confirmpassword" label="Confirm Password" variant="standard" fullWidth onChange={handleChange}/>
									{passworderror && <FormHelperText error>Your passwords have to match</FormHelperText>}
								</MDBox>
							</Grid>
						</Grid>

						<MDBox alignItems="center" ml={1} mt={4}>
							<Checkbox       
      						onChange={handleTerms}
      						inputProps={{ 'aria-label': 'controlled' }} 
							/>
							<MDTypography
								variant="button"
								fontWeight="regular"
								color="text"
								sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
								display="inline"
							>
								&nbsp;&nbsp;I have read and agree to MyAgentFinder’s&nbsp;
							</MDTypography>
							<MDTypography
								component="a"
								href="https://www.myagentfinder.com/terms"
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
								target="_blank"
							>
								Terms of Service
							</MDTypography>
							<MDTypography
								component="a"
								href="https://www.myagentfinder.com/privacy"
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
								target="_blank"
							>
								Privacy Policy
							</MDTypography>
							<MDTypography
								component="a"
								href="https://www.myagentfinder.com/electronic-communications-consent"
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
								target="_blank"
							>
								Consent to the Use of Electronic Communications
							</MDTypography>
						</MDBox>
						<MDBox mt={4} mb={1}>
							<MDButton variant="gradient" color="solidPrimary" disabled={form} fullWidth onClick={handleRegister}>
								Get Started
							</MDButton>
						</MDBox>
						<MDBox mt={3} mb={1} textAlign="center">
							<MDTypography variant="button" color="text">
								Already have an account?{" "}
								<MDTypography
									component={Link}
									to="/"
									variant="button"
									color="info"
									fontWeight="medium"
									textGradient
								>
									Sign In
								</MDTypography>
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</CoverLayout>
	);
}

export default Cover;
