import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import MDAvatar from "components/MDAvatar";
import {
	useMaterialUIController,
	setMiniSidenav,
	setTransparentSidenav,
	setWhiteSidenav,
} from "context";
import AuthService from "services/auth.service";

import default_data from "values/default";

function Sidenav({ logout, color, brand, brandName, routes, loading, ...rest }) {
	const [openCollapse, setOpenCollapse] = useState(false);
	const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
	const location = useLocation();
	const { pathname } = location;
	const collapseName = pathname.split("/").slice(1)[0];
	const items = pathname.split("/").slice(1);
	const itemParentName = items[1];
	const itemName = items[items.length - 1];


	// Get user details from the Redux store
	const userDetails = useSelector((state) => state.data);
  const user = userDetails.agent;

	const [opportunities, setOpportunities] = useState([]);
	const [count_opp, setCount] = useState([]);
	const [count_aff, setCountAff] = useState([]);
	const arr = ["pending", "overdue", "allactive", "prospects", "clients", "undercontract", "nurture", "closed", "cancelled"];
	const arr_aff = ["outbound-prospects", "outbound-clients", "outbound-undercontract", "outbound-nurture", "outbound-closed", "outbound-cancelled"];

	useEffect(() => {
		if (Object.keys(userDetails).length !== 0) {
			const user_data = userDetails;
			let opps = user_data.agent.opportunity;
			let team_opp = {};
			if (Object.keys(user_data.team).length !== 0) {
				team_opp = user_data.team.data;
				Object.values(team_opp).map((opp) => opp.map((opp_id) => opps = [...opps, opp_id]));
			}
			if (Object.keys(user_data.agent.affiliate.opportunity).length !== 0) {
				setAffiliates(user_data.agent.affiliate.opportunity);
			}
			setOpportunities(opps);
			filterOpps(opps);
		}
	}, [userDetails]);

	const filterOpps = (opps) => {
		const count = {};
		let data = opps.filter((opp) => opp.stageName === 'Listed' || opp.stageName === 'Client');
		count['clients'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'In Escrow');
		count['undercontract'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'No Longer Engaged' || opp.stageName == 'Closed Lost');
		count['cancelled'] = data.length;
		data = opps.filter((opp) => (opp.stageName === 'Assigned' || opp.stageName === 'Prospect' || opp.stageName === 'Listed' || opp.stageName === 'Client' || opp.stageName === 'In Escrow' || opp.stageName === 'Nurture') && opp.currentStatus !== "Not Accepted");
		count['allactive'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'Closed Won' || opp.stageName === 'Closed Escrow');
		count['closed'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'Nurture');
		count['nurture'] = data.length;
		data = opps.filter((opp) => opp.currentStatus === 'Not Accepted');
		count['pending'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'Assigned' || opp.stageName === 'Prospect');
		count['prospects'] = data.length;
		data = opps.filter((opp) => opp.updateWithinDays < 0);
		count['overdue'] = data.length;
		setCount(count);
	}

	const setAffiliates = (opps) => {
		const count = {};
		let data = opps.filter((opp) => opp.stageName === 'Listed' || opp.stageName === 'Client');
		count['outbound-clients'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'In Escrow');
		count['outbound-undercontract'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'No Longer Engaged' || opp.stageName === 'Closed Lost');
		count['outbound-cancelled'] = data.length;
		data = opps.filter((opp) => (opp.stageName === 'Assigned' || opp.stageName === 'Prospect' || opp.stageName === 'Listed' || opp.stageName === 'Client' || opp.stageName === 'In Escrow' || opp.stageName === 'Nurture') && opp.currentStatus !== "Not Accepted");
		count['outbound-allactive'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'Closed Won' || opp.stageName === 'Closed Escrow');
		count['outbound-closed'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'Nurture');
		count['outbound-nurture'] = data.length;
		data = opps.filter((opp) => opp.currentStatus === 'Not Accepted');
		count['outbound-pending'] = data.length;
		data = opps.filter((opp) => opp.stageName === 'Assigned' || opp.stageName === 'Prospect');
		count['outbound-prospects'] = data.length;
		data = opps.filter((opp) => opp.updateWithinDays < 0);
		count['outbound-overdue'] = data.length;
		setCountAff(count);
	}

	let textColor = "white";

	if (transparentSidenav || (whiteSidenav && !darkMode)) {
		textColor = "dark";
	} else if (whiteSidenav && darkMode) {
		textColor = "inherit";
	}

	const logoutfunction = () => {
		AuthService.handleLogout();
	};
	const closeSidenav = () => setMiniSidenav(dispatch, true);

	useEffect(() => {
		setOpenCollapse(collapseName);
		setOpenNestedCollapse(itemParentName);
	}, []);

	useEffect(() => {
		function handleMiniSidenav() {
			setMiniSidenav(dispatch, window.innerWidth < 1200);
			setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
			setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
		}

		window.addEventListener("resize", handleMiniSidenav);

		handleMiniSidenav();

		return () => window.removeEventListener("resize", handleMiniSidenav);
	}, [dispatch, location]);

	const renderNestedCollapse = (collapse) => {
		const template = collapse.map(({ name, route, key, href }) =>
			href ? (
				<Link
					key={key}
					href={href}
					target="_blank"
					rel="noreferrer"
					sx={{ textDecoration: "none" }}
				>
					<SidenavItem name={name} nested />
				</Link>
			) : (
				<NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
					<SidenavItem name={name} active={route === pathname} nested />
				</NavLink>
			)
		);

		return template;
	};

	const renderCollapse = (collapses) =>
		collapses.map(({ name, collapse, route, href, key }) => {
			let returnValue;
			if (collapse) {
				returnValue = (
					<SidenavItem
						key={key}
						color={color}
						name={name}
						active={key === itemParentName ? "isParent" : false}
						open={openNestedCollapse === key}
						onClick={({ currentTarget }) =>
							openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
								? setOpenNestedCollapse(false)
								: setOpenNestedCollapse(key)
						}
					>
						{renderNestedCollapse(collapse)}
					</SidenavItem>
				);
			} else {
				const data = count_opp;
				const data_aff = count_aff;
				returnValue = key === "logout" ?
					<Link
						href={href}
						key={key}
						target="_blank"
						rel="noreferrer"
						sx={{ textDecoration: "none" }}
						onClick={logoutfunction}
					>
						<SidenavItem color={color} name={name} active={key === itemName} />
					</Link> : href ? (
						<Link
							href={href}
							key={key}
							target="_blank"
							rel="noreferrer"
							sx={{ textDecoration: "none" }}
						>
							<SidenavItem color={color} name={name} active={key === itemName} />
						</Link>
					) : (arr.includes(key) && route.includes("/referral")) ? (
						<NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
							<SidenavItem color={color} name={name} count={data[key]} active={key === itemName} />
						</NavLink>
					) : (arr_aff.includes(key) && route.includes("/out-bound/")) ? (
						<NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
							<SidenavItem color={color} name={name} count={data_aff[key]} active={key === itemName} />
						</NavLink>
					) : (
						<NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
							<SidenavItem color={color} name={name} active={key === itemName} style={name === "My Plan" && !default_data.agentWhiteList.includes(userDetails.agent.email) ? { display: "none" } : { display: "block" }} />
						</NavLink>
					);
			}
			return <SidenavList key={key}>{returnValue}</SidenavList>;
		});

	const renderRoutes = routes.map(
		({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
			
			if (key === "outbound-manuallyadd") {
				const isWhitelisted = default_data.agentWhiteList.includes(userDetails.agent.email);
	
				if (!isWhitelisted) {
					return null;
				}

				if (
					userDetails.agent.subscription_plan === default_data.subscription_plans.free_profile ||
					userDetails.agent.subscription_status === default_data.subscription_status.canceled
				) {
					return null;
				}
			}

			let returnValue;

			if (type === "collapse") {
				if (name === "user_data") {
					icon = <MDAvatar src={user.profile_image} alt="profile Image" size="sm" />;
					name = user.firstname + " " + user.lastname;
				}
				if (href) {
					returnValue = (
						<Link
							href={href}
							key={key}
							target="_blank"
							rel="noreferrer"
							sx={{ textDecoration: "none" }}
						>
							<SidenavCollapse
								name={name}
								icon={icon}
								active={key === collapseName}
								noCollapse={noCollapse}
							/>
						</Link>
					);
				} else if (noCollapse && route) {
					returnValue = (
						<NavLink to={route} key={key}>
							<SidenavCollapse
								name={name}
								icon={icon}
								noCollapse={noCollapse}
								active={key === collapseName}
							>
								{collapse ? renderCollapse(collapse) : null}
							</SidenavCollapse>
						</NavLink>
					);
				} else {
					returnValue = (
						<SidenavCollapse
							key={key}
							name={name}
							icon={icon}
							active={key === collapseName}
							open={openCollapse === key}
							onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
						>
							{collapse ? renderCollapse(collapse) : null}
						</SidenavCollapse>
					);
				}
			} else if (type === "title") {
				returnValue = (
					<MDTypography
						key={key}
						color={textColor}
						display="block"
						variant="caption"
						fontWeight="bold"
						textTransform="uppercase"
						pl={3}
						mt={2}
						mb={1}
						ml={1}
					>
						{title}
					</MDTypography>
				);
			} else if (type === "divider") {
				returnValue = (
					<Divider
						key={key}
						light={
							(!darkMode && !whiteSidenav && !transparentSidenav) ||
							(darkMode && !transparentSidenav && whiteSidenav)
						}
					/>
				);
			} else if (type === "logout") {
				returnValue = (
					<span onClick={logoutfunction}>More</span>
				)
			}
			return returnValue;
		}
	);

	const renderLogo = () => {
		if (miniSidenav) {
			return brand && <MDBox component="img" src="/maf-logo.svg" alt="Brand" width="3rem" />;
		} else {
			return brand && <MDBox component="img" src="/maf-logo.svg" alt="Brand" width="10rem" />;
		}
	}

	return (
		<SidenavRoot

			{...rest}
			variant="permanent"
			ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
		>
			<MDBox pt={3} pb={1} px={4} textAlign="center">
				<MDBox
					display={{ xs: "block", xl: "none" }}
					position="absolute"
					top={0}
					right={0}
					p={1.625}
					onClick={closeSidenav}
					sx={{ cursor: "pointer" }}
				>
					<MDTypography variant="h6" color="white">
						<Icon sx={{ fontWeight: "bold" }}>close</Icon>
					</MDTypography>
				</MDBox>

				<MDBox component={NavLink} to="/" display="flex" alignItems="center">
					{renderLogo}
				</MDBox>

			</MDBox>
			<Divider
				light={
					(!darkMode && !whiteSidenav && !transparentSidenav) ||
					(darkMode && !transparentSidenav && whiteSidenav)
				}
			/>
			<List>{renderRoutes}</List>
		</SidenavRoot>
	);
}

Sidenav.defaultProps = {
	color: "info",
	brand: "",
	loading: false
};

Sidenav.propTypes = {
	color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
	brand: PropTypes.string,
	brandName: PropTypes.string.isRequired,
	routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
