import { createSlice } from '@reduxjs/toolkit';
import { makeUserDetails } from 'utils/userDetails';
import { makeCompanyDetails } from 'utils/companyDetails';

const initialState = {
  success: false,
  status_code: null,
  user: {
    uuid: '',
    email: '',
    type: '',
  },
  agent: {
    firstname: '',
    lastname: '',
    position: '',
    street: '',
    city: '',
    state: '',
    expertise: '',
    associations: '',
    certifications: '',
    language: '',
    loan: '',
    account_pause: false,
    mobile: '',
    main_phone: '',
    coordinator_phone: '',
    office_assistant_phone: '',
    price_min_buyers: '',
    price_min_sellers: '',
    price_min_buyers_sellers: '',
    account_no_longer_hold_date: '',
    agent_license_date: '',
    referralTypes: [],
    is_influencer: false,
    is_sales_split: false,
    merged_influencer_agent: false,
    opportunity: [],
    affiliate: {
      opportunity: [],
    },
    profile_image: '',
    role: '',
    sfid: '',
    subscription_plan: '',
    subscription_status: '',
    subscription_price: 0,
    subscription_end_date: '',
    subscription_start_date: '',
    maxio_customer_id: '',
    maxio_subscription_id: '',
    maxio_product_id: '',
    maxio_component_id: '',

  },
  companyDetails: {
    company_name: '',
    broker_name: '',
    broker_email: '',
    broker_phone: '',
    license_date: '',
    main_phone: '',
    license: '',
    coordinator_email: '',
    coordinator_phone: '',
    coordinator_name: '',
    office_assistant_email: '',
    office_assistant_phone: '',
    office_assistant_name: '',
    other_street: '',
    other_city: '',
    other_state: '',
    other_postalcode: '',
  },
  team: {
    data: [],
  },
  isDataLoaded: false,
};

const userSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const formattedUserDetails = makeUserDetails(action.payload.agent);
      const formattedCompanyDetails = makeCompanyDetails(action.payload.agent);
      return {
        ...state, 
        ...action.payload,
        agent: { ...state.agent, ...formattedUserDetails }, 
        companyDetails: { ...state.companyDetails, ...formattedCompanyDetails },
        isDataLoaded: true 
      };
    },
    updateAgentDetails: (state, action) => {
      state.agent = { ...state.agent, ...action.payload };
    },
    updateCompanyDetails: (state, action) => {
      state.companyDetails = { ...state.companyDetails, ...action.payload };
    },
  },
});

export const { setUserData, updateAgentDetails, updateCompanyDetails } = userSlice.actions;
export default userSlice.reducer;
