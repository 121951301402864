import React from 'react';
import Title from "components/global/title";
import ButtonLink from "components/global/buttonLink";
import sha1 from 'js-sha1';
import default_data from "values/default";

const generateMaxioUrl = (subdomain, shortname, subscriptionId, sharedKey) => {
  const message = `${shortname}--${subscriptionId}--${sharedKey}`;
  const token = sha1(message).slice(0, 10);
  return `https://${subdomain}.chargifypay.com/${shortname}/${subscriptionId}/${token}`;
};

const OverviewCard = ({ activePlan, activePlanStatus, activePrice, maxioSubcriptionID }) => {

  const handleButtonClick = (e) => {
    e.preventDefault();
    const sharedKey = 'HXBuHy5tsKePSK7BTQwW';
    const subdomain = 'ire';
    const shortname = 'update_payment';
    
    const maxioUrl = generateMaxioUrl(subdomain, shortname, maxioSubcriptionID, sharedKey);

    window.open(maxioUrl, '_blank');
  };

  return (
    <div className="StatisticsCard flex flex-col md:flex-row w-full p-4 bg-white rounded-xl shadow justify-start items-center gap-2.5">
      <div className="Frame45 flex p-[8px] flex-col items-start justify-center gap-[8px] flex-[1_0_0] self-stretch ">
        <Title
          text="My Plan Details"
          className="text-[16px] md:text-[25px]"
        />
      </div>
      <div className="Frame45 flex p-[8px] flex-col items-start gap-[16px] flex-[1_0_0] self-stretch">
        <div className="Frame88 items-center w-full flex flex-col md:flex-row justify-between gap-[8px] lg:gap-[4rem]">
          <div className="Frame14183 justify-between w-full md:w-auto flex md:flex-col items-start gap-[8px]">
            <div className="Text text-[#283567] text-[14px] not-italic font-normal leading-[140%]">Plan Status</div>
            <div className={`flex px-[8px] py-[4px] items-start gap-[10px] text-[14px] not-italic font-semibold leading-[140%] rounded-[8px]
              ${activePlanStatus === default_data.subscription_status.active ? 'bg-[#CDF3B7] text-[#375525]' :
              activePlanStatus === default_data.subscription_status.pending ? 'bg-[#CACCE4] text-[#2E3570]' : 'bg-[#CACCE4] text-[#2E3570]'}`}>
              {activePlanStatus}
            </div>
          </div>
          <div className="Frame14183 justify-between w-full md:w-auto flex md:flex-col items-start gap-[8px]">
            <div className="Text text-[#283567] text-[14px] not-italic font-normal leading-[140%]">Plan</div>
            <div className="Text text-[#283567] text-[14px] not-italic font-semibold leading-[140%]">{activePlan}</div>
          </div>
          <div className="Frame14184 justify-between w-full md:w-auto flex md:flex-col items-start gap-[8px] flex-[1_0_0]">
            <div className="Text text-[#283567] text-[14px] not-italic font-normal leading-[140%]">Price</div>
            <div className="Text text-[#283567] text-[14px] not-italic font-semibold leading-[140%]">${activePrice} /month</div>
          </div>
        </div>
      </div>
      {maxioSubcriptionID !== null ?
        <div className="Frame72 flex p-[8px] flex-col items-start gap-[16px] w-full md:w-auto">
          <ButtonLink
            onClick={handleButtonClick}
            text={'Edit Billing'}
            href="#"
            className="flex flex-row text-[#6471ED] text-center text-[14px] not-italic font-semibold leading-[14px] px-[24px] py-[15px] justify-center items-center self-stretch rounded-[8px]"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M14 3.5C14 2.94772 14.4477 2.5 15 2.5H21C21.5523 2.5 22 2.94772 22 3.5V9.5C22 10.0523 21.5523 10.5 21 10.5C20.4477 10.5 20 10.0523 20 9.5V5.91421L10.7071 15.2071C10.3166 15.5976 9.68342 15.5976 9.29289 15.2071C8.90237 14.8166 8.90237 14.1834 9.29289 13.7929L18.5858 4.5H15C14.4477 4.5 14 4.05228 14 3.5Z" fill="#6471ED"/>
            <path d="M5 7.5C4.73478 7.5 4.48043 7.60536 4.29289 7.79289C4.10536 7.98043 4 8.23478 4 8.5V19.5C4 19.7652 4.10536 20.0196 4.29289 20.2071C4.48043 20.3946 4.73478 20.5 5 20.5H16C16.2652 20.5 16.5196 20.3946 16.7071 20.2071C16.8946 20.0196 17 19.7652 17 19.5V13.5C17 12.9477 17.4477 12.5 18 12.5C18.5523 12.5 19 12.9477 19 13.5V19.5C19 20.2957 18.6839 21.0587 18.1213 21.6213C17.5587 22.1839 16.7957 22.5 16 22.5H5C4.20435 22.5 3.44129 22.1839 2.87868 21.6213C2.31607 21.0587 2 20.2957 2 19.5V8.5C2 7.70435 2.31607 6.94129 2.87868 6.37868C3.44129 5.81607 4.20435 5.5 5 5.5H11C11.5523 5.5 12 5.94772 12 6.5C12 7.05228 11.5523 7.5 11 7.5H5Z" fill="#6471ED"/>
          </svg>
          </ButtonLink>
        </div>
        :
        <div className="flex p-[8px] flex-col items-start gap-[8px] w-full md:w-auto">
          <span className="text-[#283567] text-[14px] not-italic font-normal leading-[140%]">To edit billing, contact</span>
          <a className="text-[#6471ED] text-[14px] not-italic font-normal leading-[140%] [text-decoration-line:underline]" href="mailto:billing@insiderealestate.com">billing@insiderealestate.com</a>
        </div>
      }
    </div>
  );
};

export default OverviewCard;
